var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.width<768)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-hierachy",modifiers:{"modal-hierachy":true}}]},[_c('feather-icon',{attrs:{"icon":"GitPullRequestIcon"}})],1)],1)],1):_vm._e(),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.downloadBrowser()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserPlusIcon","size":"16"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('Exportar')))])],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Product"}},[_c('v-select',{attrs:{"value":_vm.$store.id,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productsOptions,"label":"name","reduce":function (product) { return product._id; },"placeholder":"Select product"},on:{"input":_vm.inputSelect},model:{value:(_vm.filterSelect.selectProduct),callback:function ($$v) {_vm.$set(_vm.filterSelect, "selectProduct", $$v)},expression:"filterSelect.selectProduct"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.datefrom'),"rules":"required"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }},on:{"input":_vm.inputSelect},model:{value:(_vm.filterSelect.dateFrom),callback:function ($$v) {_vm.$set(_vm.filterSelect, "dateFrom", $$v)},expression:"filterSelect.dateFrom"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.dateto'),"rules":"required"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }},on:{"input":_vm.inputSelect},model:{value:(_vm.filterSelect.dateTo),callback:function ($$v) {_vm.$set(_vm.filterSelect, "dateTo", $$v)},expression:"filterSelect.dateTo"}})],1)],1)],1)],1),_c('b-row',_vm._l((_vm.cardCabezera),function(item,index){return _c('b-col',{key:index,attrs:{"lg":"3","sm":"6"}})}),1),_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.loadingData,"variant":"transparent","no-wrap":""}}),_c('b-modal',{attrs:{"id":"modalResponse","title":"PopUp Response","cancel-variant":"outline-secondary","scrollable":""}},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.rows)+" ")])],1),_c('b-table',{attrs:{"items":_vm.dataItems,"fields":_vm.fields,"hover":"","small":"","caption-top":"","responsive":""},scopedSlots:_vm._u([{key:"cell(debit)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.debit,{ symbol: "" }))+" ")]}},{key:"cell(credit)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.credit,{ symbol: "" }))+" ")]}},{key:"cell(profit)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.profit,{ symbol: "" }))+" ")]}}])})],1)],1),(_vm.width<768)?_c('b-modal',{attrs:{"id":"modal-hierachy","hide-footer":"","title":"Listado de agentes"}},[_c('HierarchyUsers',{ref:"HerarchiComponent",on:{"selectUser":_vm.showData}})],1):_c('b-col',{staticClass:"p-0",attrs:{"md":"3"}},[_c('b-card',{staticClass:"p-0 py-1",attrs:{"no-body":""}},[_c('HierarchyUsers',{on:{"selectUser":_vm.showData}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }